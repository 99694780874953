.flex_column__ {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.overlay_background__ {
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 50;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.15);
}

.overlay_shown___ {
  opacity: 1;
}

.overlay_hidden___ {
  display: none;
  opacity: 0;
}

.overlay_card__ {
  width: 200px;
  height: 100px;
  border-radius: 10px;
  z-index: 51;
  background-color: rgb(0 0 0 / 0%);
  display: flex;
  justify-content: center;
}



.login_button:hover,
.login_button:active {
  box-shadow: inset 0 0 40px rgba(256, 256, 256, 0.3);
}

.login_button {
  background-color: rgb(95, 215, 95);
}