.datepicker {
    padding: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    direction: ltr;
}

.datepicker-inline {
    width: 220px;
}

.datepicker-rtl {
    direction: rtl;
}

.datepicker-rtl.dropdown-menu {
    left: auto;
}

.datepicker-rtl table tr td span {
    float: right;
}

.datepicker-dropdown {
    top: 0;
    left: 0;
}

.datepicker-dropdown:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #999;
    border-top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
}

.datepicker-dropdown:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-top: 0;
    position: absolute;
}

.datepicker-dropdown.datepicker-orient-left:before {
    left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
    left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
    right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
    right: 7px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
    top: -7px;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
    top: -6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
    bottom: -7px;
    border-bottom: 0;
    border-top: 7px solid #999;
}

.datepicker-dropdown.datepicker-orient-top:after {
    bottom: -6px;
    border-bottom: 0;
    border-top: 6px solid #fff;
}

.datepicker table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.datepicker td,
.datepicker th {
    text-align: center;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: none;
}

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
    background-color: transparent;
}

.datepicker table tr td.day:hover,
.datepicker table tr td.day.focused {
    background: #eee;
    cursor: pointer;
}

.datepicker table tr td.old,
.datepicker table tr td.new {
    color: #999;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
    background: none;
    color: #999;
    cursor: default;
}

.datepicker table tr td.highlighted {
    background: #d9edf7;
    border-radius: 0;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
    background-color: #fde19a;
    background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
    background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
    border-color: #fdf59a #fdf59a #fbed50;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #000;
}

.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
    background-color: #fdf59a;
}

.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active {
    background-color: #fbf069 \9;
}

.datepicker table tr td.today:hover:hover {
    color: #000;
}

.datepicker table tr td.today.active:hover {
    color: #fff;
}

.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
    background: #eee;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover {
    background-color: #f3d17a;
    background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
    background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
    background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
    background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
    background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
    border-color: #f3e97a #f3e97a #edde34;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today[disabled],
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today.disabled:hover[disabled] {
    background-color: #f3e97a;
}

.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active {
    background-color: #efe24b \9;
}

.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
    background-color: #9e9e9e;
    background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
    background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
    background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
    background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
    background-image: linear-gradient(to bottom, #b3b3b3, #808080);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
    border-color: #808080 #808080 #595959;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected[disabled],
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected.disabled:hover[disabled] {
    background-color: #808080;
}

.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active {
    background-color: #666666 \9;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
    background-color: #006dcc;
    background-image: -moz-linear-gradient(to bottom, #08c, #0044cc);
    background-image: -ms-linear-gradient(to bottom, #08c, #0044cc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#0044cc));
    background-image: -webkit-linear-gradient(to bottom, #08c, #0044cc);
    background-image: -o-linear-gradient(to bottom, #08c, #0044cc);
    background-image: linear-gradient(to bottom, #08c, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
    background-color: #0044cc;
}

.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
    background-color: #003399 \9;
}

.datepicker table tr td span {
    display: block;
    width: 23%;
    height: 54px;
    line-height: 54px;
    float: left;
    margin: 1%;
    cursor: pointer;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
    background: #eee;
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
    background: none;
    color: #999;
    cursor: default;
}

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
    background-color: #006dcc;
    background-image: -moz-linear-gradient(to bottom, #08c, #0044cc);
    background-image: -ms-linear-gradient(to bottom, #08c, #0044cc);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#0044cc));
    background-image: -webkit-linear-gradient(to bottom, #08c, #0044cc);
    background-image: -o-linear-gradient(to bottom, #08c, #0044cc);
    background-image: linear-gradient(to bottom, #08c, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
    background-color: #0044cc;
}

.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
    background-color: #003399 \9;
}

.datepicker table tr td span.old,
.datepicker table tr td span.new {
    color: #999;
}

.datepicker .datepicker-switch {
    width: 145px;
}

.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next,
.datepicker tfoot tr th {
    cursor: pointer;
}

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
    background: #eee;
}

.datepicker .prev.disabled,
.datepicker .next.disabled {
    visibility: hidden;
}

.datepicker .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
}

.input-append.date .add-on,
.input-prepend.date .add-on {
    cursor: pointer;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
    margin-top: 3px;
}

.input-daterange input {
    text-align: center;
}

.input-daterange input:first-child {
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px;
}

.input-daterange input:last-child {
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
}

.input-daterange .add-on {
    display: inline-block;
    width: auto;
    min-width: 16px;
    height: 18px;
    padding: 4px 5px;
    font-weight: normal;
    line-height: 18px;
    text-align: center;
    text-shadow: 0 1px 0 #fff;
    vertical-align: middle;
    background-color: #eee;
    border: 1px solid #ccc;
    margin-left: -5px;
    margin-right: -5px;
}


select.bs-select-hidden,
select.selectpicker {
    display: none !important;
}

.bootstrap-select {
    width: 220px \0;

}

.bootstrap-select>.dropdown-toggle {
    width: 100%;
    padding-right: 25px;
    z-index: 1;
}

.bootstrap-select>.dropdown-toggle.bs-placeholder,
.bootstrap-select>.dropdown-toggle.bs-placeholder:hover,
.bootstrap-select>.dropdown-toggle.bs-placeholder:focus,
.bootstrap-select>.dropdown-toggle.bs-placeholder:active {
    color: #FFF;
}

.bootstrap-select>select {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    display: block !important;
    width: 0.5px !important;
    height: 100% !important;
    padding: 0 !important;
    opacity: 0 !important;
    border: none;
}

.bootstrap-select>select.mobile-device {
    top: 0;
    left: 0;
    display: block !important;
    width: 100% !important;
    z-index: 2;
}

.has-error .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle {
    border-color: #b94a48;
}

.bootstrap-select.fit-width {
    width: auto !important;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 220px;
}

.bootstrap-select .dropdown-toggle:focus {
    outline: thin dotted #333333 !important;
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: -2px;
}

.bootstrap-select.form-control {
    margin-bottom: 0;
    padding: 0;
    border: none;
}

.bootstrap-select.form-control:not([class*="col-"]) {
    width: 100%;
}

.bootstrap-select.form-control.input-group-btn {
    z-index: auto;
}

.bootstrap-select.form-control.input-group-btn:not(:first-child):not(:last-child)>.btn {
    border-radius: 0;
}

.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*="col-"] {
    float: none;
    display: inline-block;
    margin-left: 0;
}

.bootstrap-select.btn-group.dropdown-menu-right,
.bootstrap-select.btn-group[class*="col-"].dropdown-menu-right,
.row .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
    float: right;
}

.form-inline .bootstrap-select.btn-group,
.form-horizontal .bootstrap-select.btn-group,
.form-group .bootstrap-select.btn-group {
    margin-bottom: 0;
}

.form-group-lg .bootstrap-select.btn-group.form-control,
.form-group-sm .bootstrap-select.btn-group.form-control {
    padding: 0;
}

.form-group-lg .bootstrap-select.btn-group.form-control .dropdown-toggle,
.form-group-sm .bootstrap-select.btn-group.form-control .dropdown-toggle {
    height: 100%;
    font-size: inherit;
    line-height: inherit;
    border-radius: inherit;
}

.form-inline .bootstrap-select.btn-group .form-control {
    width: 100%;
}

.bootstrap-select.btn-group.disabled,
.bootstrap-select.btn-group>.disabled {
    cursor: not-allowed;
}

.bootstrap-select.btn-group.disabled:focus,
.bootstrap-select.btn-group>.disabled:focus {
    outline: none !important;
}

.bootstrap-select.btn-group.bs-container {
    position: absolute;
    height: 0 !important;
    padding: 0 !important;
}

.bootstrap-select.btn-group.bs-container .dropdown-menu {
    z-index: 1060;
}

.bootstrap-select.btn-group .dropdown-toggle .filter-option {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    text-align: left;
}

.bootstrap-select.btn-group .dropdown-toggle .caret {
    position: absolute;
    top: 50%;
    right: 12px;
    margin-top: -2px;
    vertical-align: middle;
}

.bootstrap-select.btn-group[class*="col-"] .dropdown-toggle {
    width: 100%;
}

.bootstrap-select.btn-group .dropdown-menu {
    min-width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.bootstrap-select.btn-group .dropdown-menu.inner {
    position: static;
    float: none;
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.bootstrap-select.btn-group .dropdown-menu li {
    position: relative;
}

.bootstrap-select.btn-group .dropdown-menu li.active small {
    color: #fff;
}

.bootstrap-select.btn-group .dropdown-menu li.disabled a {
    cursor: not-allowed;
}

.bootstrap-select.btn-group .dropdown-menu li a {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.bootstrap-select.btn-group .dropdown-menu li a.opt {
    position: relative;
    padding-left: 2.25em;
}

.bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
    display: none;
}

.bootstrap-select.btn-group .dropdown-menu li a span.text {
    display: inline-block;
}

.bootstrap-select.btn-group .dropdown-menu li small {
    padding-left: 0.5em;
}

.bootstrap-select.btn-group .dropdown-menu .notify {
    position: absolute;
    bottom: 5px;
    width: 96%;
    margin: 0 2%;
    min-height: 26px;
    padding: 3px 5px;
    background: #f5f5f5;
    border: 1px solid #e3e3e3;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    pointer-events: none;
    opacity: 0.9;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.bootstrap-select.btn-group .no-results {
    padding: 3px;
    background: #f5f5f5;
    margin: 0 5px;
    white-space: nowrap;
}

.bootstrap-select.btn-group.fit-width .dropdown-toggle .filter-option {
    position: static;
}

.bootstrap-select.btn-group.fit-width .dropdown-toggle .caret {
    position: static;
    top: auto;
    margin-top: -1px;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
    position: absolute;
    display: inline-block;
    right: 15px;
    margin-top: 5px;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
    margin-right: 34px;
}

.bootstrap-select.show-menu-arrow.open>.dropdown-toggle {
    z-index: 1061;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
    content: '';
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgba(204, 204, 204, 0.2);
    position: absolute;
    bottom: -4px;
    left: 9px;
    display: none;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid white;
    position: absolute;
    bottom: -4px;
    left: 10px;
    display: none;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
    bottom: auto;
    top: -3px;
    border-top: 7px solid rgba(204, 204, 204, 0.2);
    border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
    bottom: auto;
    top: -3px;
    border-top: 6px solid white;
    border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
    right: 12px;
    left: auto;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
    right: 13px;
    left: auto;
}

.bootstrap-select.show-menu-arrow.open>.dropdown-toggle:before,
.bootstrap-select.show-menu-arrow.open>.dropdown-toggle:after {
    display: block;
}

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
    padding: 4px 8px;
}

.bs-actionsbox {
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.bs-actionsbox .btn-group button {
    width: 50%;
}

.bs-donebutton {
    float: left;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.bs-donebutton .btn-group button {
    width: 100%;
}

.bs-searchbox+.bs-actionsbox {
    padding: 0 8px 4px;
}

.bs-searchbox .form-control {
    margin-bottom: 0;
    width: 100%;
    float: none;
}






.checker {
    position: relative;
    display: inline-block;
    cursor: pointer;
    vertical-align: middle
}

.checker,
.checker input,
.checker span {
    width: 18px;
    height: 18px
}

.checker span {
    color: #565555;
    border: 2px solid #565555;
    display: inline-block;
    text-align: center;
    position: relative;
    border-radius: 2px
}

.checker span:after {
    content: "\ea10";
    font-family: icomoon;
    font-size: 16px;
    color: inherit;
    line-height: 1;
    position: absolute;
    top: -1px;
    left: -1px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    opacity: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transition-duration: .1s;
    transition-duration: .1s
}

.checker span.checked {
    border-color: transparent;
    -webkit-transition-duration: .1s;
    transition-duration: .1s
}

.checker span.checked:after {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
}

.checker input[type=checkbox],
.radio input[type=radio] {
    border: none;
    background: 0 0;
    display: -moz-inline-box;
    display: inline-block;
    margin: 0;
    vertical-align: top;
    cursor: pointer;
    position: absolute;
    top: -2px;
    left: -2px;
    z-index: 2;
    opacity: 0
}

.checkbox .checker,
.checkbox-inline .checker {
    position: absolute;
    top: 1px;
    left: 0
}

.form-horizontal .checkbox .checker,
.form-horizontal .checkbox-inline .checker {
    top: 10px
}

.checkbox-right .checker {
    left: auto;
    right: 0
}

.checker.disabled {
    opacity: .5
}

.checker.disabled,
.checker.disabled input[type=checkbox] {
    cursor: not-allowed
}

.checkbox-inline:active .checker.disabled span:after,
.checkbox>label:active .checker.disabled span:after {
    opacity: 0
}

.checkbox-inline:active .checker.disabled span.checked:after,
.checkbox>label:active .checker.disabled span.checked:after {
    opacity: 1
}

.checker[class*=border-] span {
    color: inherit
}

.checker[class*=border-] span:not(.checked) {
    border-color: inherit
}


.checker span:after,
.radio span:after {
    -webkit-transition: all ease-in-out .15s;
    -o-transition: all ease-in-out .15s;
    transition: all ease-in-out .15s
}

.checker span {
    -webkit-transition: border-color ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s;
    transition: border-color ease-in-out .15s
}


.radio {
    position: relative;
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;
    border-radius: 100%
}

.radio,
.radio input,
.radio span {
    width: 18px;
    height: 18px;
    margin: 0;
}

.radio span {
    border: 2px solid #565555;
    display: -moz-inline-box;
    display: inline-block;
    border-radius: 100%;
    text-align: center;
    position: relative
}

.radio span:after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    border: 4px solid;
    border-color: inherit;
    width: 0;
    height: 0;
    border-radius: 100%;
    opacity: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0)
}

.radio span.checked:after {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
}

.radio .radio,
.radio-inline .radio {
    position: absolute;
    top: 1px;
    left: 0
}

.form-horizontal .radio .radio,
.form-horizontal .radio-inline .radio {
    top: 10px
}

.radio-right .radio {
    left: auto;
    right: 0
}

.radio.active span:after,
.radio-inline:active .radio span:after,
.radio>label:active .radio span:after {
    opacity: .75
}

.radio.disabled {
    opacity: .5
}

.radio.disabled,
.radio.disabled input[type=radio] {
    cursor: not-allowed
}

.radio-inline:active .radio.disabled span:after,
.radio>label:active .radio.disabled span:after {
    opacity: 0
}

.radio-inline:active .radio.disabled span.checked:after,
.radio>label:active .radio.disabled span.checked:after {
    opacity: 1
}

.radio[class*=border-] span {
    border-color: inherit
}

.radio[class*=border-] span:after {
    border-color: inherit
}




body.stop-scrolling {
    height: 100%;
    overflow: hidden;
}

.sweet-overlay {
    background-color: black;

    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";

    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    z-index: 10000;
}

.sweet-alert {
    background-color: white;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    width: 478px;
    padding: 17px;
    border-radius: 5px;
    text-align: center;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -256px;
    margin-top: -200px;
    overflow: hidden;
    display: none;
    z-index: 99999;
}

@media all and (max-width: 540px) {
    .sweet-alert {
        width: auto;
        margin-left: 0;
        margin-right: 0;
        left: 15px;
        right: 15px;
    }
}

.sweet-alert h2 {
    color: #575757;
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    text-transform: none;
    position: relative;
    margin: 25px 0;
    padding: 0;
    line-height: 40px;
    display: block;
}

.sweet-alert p {
    color: #797979;
    font-size: 16px;
    text-align: center;
    font-weight: 300;
    position: relative;
    text-align: inherit;
    float: none;
    margin: 0;
    padding: 0;
    line-height: normal;
}

.sweet-alert fieldset {
    border: none;
    position: relative;
}

.sweet-alert .sa-error-container {
    background-color: #f1f1f1;
    margin-left: -17px;
    margin-right: -17px;
    overflow: hidden;
    padding: 0 10px;
    max-height: 0;
    webkit-transition: padding 0.15s, max-height 0.15s;
    transition: padding 0.15s, max-height 0.15s;
}

.sweet-alert .sa-error-container.show {
    padding: 10px 0;
    max-height: 100px;
    webkit-transition: padding 0.2s, max-height 0.2s;
    transition: padding 0.25s, max-height 0.25s;
}

.sweet-alert .sa-error-container .icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #ea7d7d;
    color: white;
    line-height: 24px;
    text-align: center;
    margin-right: 3px;
}

.sweet-alert .sa-error-container p {
    display: inline-block;
}

.sweet-alert .sa-input-error {
    position: absolute;
    top: 29px;
    right: 26px;
    width: 20px;
    height: 20px;
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
}

.sweet-alert .sa-input-error::before,
.sweet-alert .sa-input-error::after {
    content: "";
    width: 20px;
    height: 6px;
    background-color: #f06e57;
    border-radius: 3px;
    position: absolute;
    top: 50%;
    margin-top: -4px;
    left: 50%;
    margin-left: -9px;
}

.sweet-alert .sa-input-error::before {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.sweet-alert .sa-input-error::after {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.sweet-alert .sa-input-error.show {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.sweet-alert input {
    width: 100%;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #d7d7d7;
    height: 43px;
    margin-top: 10px;
    margin-bottom: 17px;
    font-size: 18px;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.06);
    padding: 0 12px;
    display: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.sweet-alert input:focus {
    outline: none;
    box-shadow: 0px 0px 3px #c4e6f5;
    border: 1px solid #b4dbed;
}

.sweet-alert input:focus::-moz-placeholder {
    transition: opacity 0.3s 0.03s ease;
    opacity: 0.5;
}

.sweet-alert input:focus:-ms-input-placeholder {
    transition: opacity 0.3s 0.03s ease;
    opacity: 0.5;
}

.sweet-alert input:focus::-webkit-input-placeholder {
    transition: opacity 0.3s 0.03s ease;
    opacity: 0.5;
}

.sweet-alert input::-moz-placeholder {
    color: #bdbdbd;
}

.sweet-alert input::-ms-clear {
    display: none;
}

.sweet-alert input:-ms-input-placeholder {
    color: #bdbdbd;
}

.sweet-alert input::-webkit-input-placeholder {
    color: #bdbdbd;
}

.sweet-alert.show-input input {
    display: block;
}

.sweet-alert .sa-confirm-button-container {
    display: inline-block;
    position: relative;
}

.sweet-alert .la-ball-fall {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -27px;
    margin-top: 4px;
    opacity: 0;
    visibility: hidden;
}

.sweet-alert button {
    background-color: #8CD4F5;
    color: white;
    border: none;
    box-shadow: none;
    font-size: 17px;
    font-weight: 500;
    -webkit-border-radius: 4px;
    border-radius: 5px;
    padding: 10px 32px;
    margin: 26px 5px 0 5px;
    cursor: pointer;
}

.sweet-alert button:focus {
    outline: none;
    box-shadow: 0 0 2px rgba(128, 179, 235, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.05);
}

.sweet-alert button:hover {
    background-color: #7ecff4;
}

.sweet-alert button:active {
    background-color: #5dc2f1;
}

.sweet-alert button.cancel {
    background-color: #C1C1C1;
}

.sweet-alert button.cancel:hover {
    background-color: #b9b9b9;
}

.sweet-alert button.cancel:active {
    background-color: #a8a8a8;
}

.sweet-alert button.cancel:focus {
    box-shadow: rgba(197, 205, 211, 0.8) 0px 0px 2px, rgba(0, 0, 0, 0.0470588) 0px 0px 0px 1px inset !important;
}

.sweet-alert button[disabled] {
    opacity: .6;
    cursor: default;
}

.sweet-alert button.confirm[disabled] {
    color: transparent;
}

.sweet-alert button.confirm[disabled]~.la-ball-fall {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
}

.sweet-alert button::-moz-focus-inner {
    border: 0;
}

.sweet-alert[data-has-cancel-button=false] button {
    box-shadow: none !important;
}

.sweet-alert[data-has-confirm-button=false][data-has-cancel-button=false] {
    padding-bottom: 40px;
}

.sweet-alert .sa-icon {
    width: 80px;
    height: 80px;
    border: 4px solid gray;
    -webkit-border-radius: 40px;
    border-radius: 40px;
    border-radius: 50%;
    margin: 20px auto;
    padding: 0;
    position: relative;
    box-sizing: content-box;
}

.sweet-alert .sa-icon.sa-error {
    border-color: #F27474;
}

.sweet-alert .sa-icon.sa-error .sa-x-mark {
    position: relative;
    display: block;
}

.sweet-alert .sa-icon.sa-error .sa-line {
    position: absolute;
    height: 5px;
    width: 47px;
    background-color: #F27474;
    display: block;
    top: 37px;
    border-radius: 2px;
}

.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 17px;
}

.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    right: 16px;
}

.sweet-alert .sa-icon.sa-warning {
    border-color: #F8BB86;
}

.sweet-alert .sa-icon.sa-warning .sa-body {
    position: absolute;
    width: 5px;
    height: 47px;
    left: 50%;
    top: 10px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    margin-left: -2px;
    background-color: #F8BB86;
}

.sweet-alert .sa-icon.sa-warning .sa-dot {
    position: absolute;
    width: 7px;
    height: 7px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    margin-left: -3px;
    left: 50%;
    bottom: 10px;
    background-color: #F8BB86;
}

.sweet-alert .sa-icon.sa-info {
    border-color: #C9DAE1;
}

.sweet-alert .sa-icon.sa-info::before {
    content: "";
    position: absolute;
    width: 5px;
    height: 29px;
    left: 50%;
    bottom: 17px;
    border-radius: 2px;
    margin-left: -2px;
    background-color: #C9DAE1;
}

.sweet-alert .sa-icon.sa-info::after {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-left: -3px;
    top: 19px;
    background-color: #C9DAE1;
    left: 50%;
}

.sweet-alert .sa-icon.sa-success {
    border-color: #A5DC86;
}

.sweet-alert .sa-icon.sa-success::before,
.sweet-alert .sa-icon.sa-success::after {
    content: '';
    -webkit-border-radius: 40px;
    border-radius: 40px;
    border-radius: 50%;
    position: absolute;
    width: 60px;
    height: 120px;
    background: white;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.sweet-alert .sa-icon.sa-success::before {
    -webkit-border-radius: 120px 0 0 120px;
    border-radius: 120px 0 0 120px;
    top: -7px;
    left: -33px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 60px 60px;
    transform-origin: 60px 60px;
}

.sweet-alert .sa-icon.sa-success::after {
    -webkit-border-radius: 0 120px 120px 0;
    border-radius: 0 120px 120px 0;
    top: -11px;
    left: 30px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 0px 60px;
    transform-origin: 0px 60px;
}

.sweet-alert .sa-icon.sa-success .sa-placeholder {
    width: 80px;
    height: 80px;
    border: 4px solid rgba(165, 220, 134, 0.2);
    -webkit-border-radius: 40px;
    border-radius: 40px;
    border-radius: 50%;
    box-sizing: content-box;
    position: absolute;
    left: -4px;
    top: -4px;
    z-index: 2;
}

.sweet-alert .sa-icon.sa-success .sa-fix {
    width: 5px;
    height: 90px;
    background-color: white;
    position: absolute;
    left: 28px;
    top: 8px;
    z-index: 1;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.sweet-alert .sa-icon.sa-success .sa-line {
    height: 5px;
    background-color: #A5DC86;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 2;
}

.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
    width: 25px;
    left: 14px;
    top: 46px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
    width: 47px;
    right: 8px;
    top: 38px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.sweet-alert .sa-icon.sa-custom {
    background-size: contain;
    border-radius: 0;
    border: none;
    background-position: center center;
    background-repeat: no-repeat;
}

@-webkit-keyframes showSweetAlert {
    0% {
        transform: scale(0.7);
        -webkit-transform: scale(0.7);
    }

    45% {
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
    }

    80% {
        transform: scale(0.95);
        -webkit-transform: scale(0.95);
    }

    100% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

@keyframes showSweetAlert {
    0% {
        transform: scale(0.7);
        -webkit-transform: scale(0.7);
    }

    45% {
        transform: scale(1.05);
        -webkit-transform: scale(1.05);
    }

    80% {
        transform: scale(0.95);
        -webkit-transform: scale(0.95);
    }

    100% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

@-webkit-keyframes hideSweetAlert {
    0% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }

    100% {
        transform: scale(0.5);
        -webkit-transform: scale(0.5);
    }
}

@keyframes hideSweetAlert {
    0% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }

    100% {
        transform: scale(0.5);
        -webkit-transform: scale(0.5);
    }
}

@-webkit-keyframes slideFromTop {
    0% {
        top: 0%;
    }

    100% {
        top: 50%;
    }
}

@keyframes slideFromTop {
    0% {
        top: 0%;
    }

    100% {
        top: 50%;
    }
}

@-webkit-keyframes slideToTop {
    0% {
        top: 50%;
    }

    100% {
        top: 0%;
    }
}

@keyframes slideToTop {
    0% {
        top: 50%;
    }

    100% {
        top: 0%;
    }
}

@-webkit-keyframes slideFromBottom {
    0% {
        top: 70%;
    }

    100% {
        top: 50%;
    }
}

@keyframes slideFromBottom {
    0% {
        top: 70%;
    }

    100% {
        top: 50%;
    }
}

@-webkit-keyframes slideToBottom {
    0% {
        top: 50%;
    }

    100% {
        top: 70%;
    }
}

@keyframes slideToBottom {
    0% {
        top: 50%;
    }

    100% {
        top: 70%;
    }
}

.showSweetAlert[data-animation=pop] {
    -webkit-animation: showSweetAlert 0.3s;
    animation: showSweetAlert 0.3s;
}

.showSweetAlert[data-animation=none] {
    -webkit-animation: none;
    animation: none;
}

.showSweetAlert[data-animation=slide-from-top] {
    -webkit-animation: slideFromTop 0.3s;
    animation: slideFromTop 0.3s;
}

.showSweetAlert[data-animation=slide-from-bottom] {
    -webkit-animation: slideFromBottom 0.3s;
    animation: slideFromBottom 0.3s;
}

.hideSweetAlert[data-animation=pop] {
    -webkit-animation: hideSweetAlert 0.2s;
    animation: hideSweetAlert 0.2s;
}

.hideSweetAlert[data-animation=none] {
    -webkit-animation: none;
    animation: none;
}

.hideSweetAlert[data-animation=slide-from-top] {
    -webkit-animation: slideToTop 0.4s;
    animation: slideToTop 0.4s;
}

.hideSweetAlert[data-animation=slide-from-bottom] {
    -webkit-animation: slideToBottom 0.3s;
    animation: slideToBottom 0.3s;
}

@-webkit-keyframes animateSuccessTip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }

    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }

    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }

    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }

    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes animateSuccessTip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }

    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }

    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }

    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }

    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@-webkit-keyframes animateSuccessLong {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }

    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }

    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }

    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

@keyframes animateSuccessLong {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }

    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }

    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }

    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

@-webkit-keyframes rotatePlaceholder {
    0% {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    5% {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    12% {
        transform: rotate(-405deg);
        -webkit-transform: rotate(-405deg);
    }

    100% {
        transform: rotate(-405deg);
        -webkit-transform: rotate(-405deg);
    }
}

@keyframes rotatePlaceholder {
    0% {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    5% {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    12% {
        transform: rotate(-405deg);
        -webkit-transform: rotate(-405deg);
    }

    100% {
        transform: rotate(-405deg);
        -webkit-transform: rotate(-405deg);
    }
}

.animateSuccessTip {
    -webkit-animation: animateSuccessTip 0.75s;
    animation: animateSuccessTip 0.75s;
}

.animateSuccessLong {
    -webkit-animation: animateSuccessLong 0.75s;
    animation: animateSuccessLong 0.75s;
}

.sa-icon.sa-success.animate::after {
    -webkit-animation: rotatePlaceholder 4.25s ease-in;
    animation: rotatePlaceholder 4.25s ease-in;
}

@-webkit-keyframes animateErrorIcon {
    0% {
        transform: rotateX(100deg);
        -webkit-transform: rotateX(100deg);
        opacity: 0;
    }

    100% {
        transform: rotateX(0deg);
        -webkit-transform: rotateX(0deg);
        opacity: 1;
    }
}

@keyframes animateErrorIcon {
    0% {
        transform: rotateX(100deg);
        -webkit-transform: rotateX(100deg);
        opacity: 0;
    }

    100% {
        transform: rotateX(0deg);
        -webkit-transform: rotateX(0deg);
        opacity: 1;
    }
}

.animateErrorIcon {
    -webkit-animation: animateErrorIcon 0.5s;
    animation: animateErrorIcon 0.5s;
}

@-webkit-keyframes animateXMark {
    0% {
        transform: scale(0.4);
        -webkit-transform: scale(0.4);
        margin-top: 26px;
        opacity: 0;
    }

    50% {
        transform: scale(0.4);
        -webkit-transform: scale(0.4);
        margin-top: 26px;
        opacity: 0;
    }

    80% {
        transform: scale(1.15);
        -webkit-transform: scale(1.15);
        margin-top: -6px;
    }

    100% {
        transform: scale(1);
        -webkit-transform: scale(1);
        margin-top: 0;
        opacity: 1;
    }
}

@keyframes animateXMark {
    0% {
        transform: scale(0.4);
        -webkit-transform: scale(0.4);
        margin-top: 26px;
        opacity: 0;
    }

    50% {
        transform: scale(0.4);
        -webkit-transform: scale(0.4);
        margin-top: 26px;
        opacity: 0;
    }

    80% {
        transform: scale(1.15);
        -webkit-transform: scale(1.15);
        margin-top: -6px;
    }

    100% {
        transform: scale(1);
        -webkit-transform: scale(1);
        margin-top: 0;
        opacity: 1;
    }
}

.animateXMark {
    -webkit-animation: animateXMark 0.5s;
    animation: animateXMark 0.5s;
}

@-webkit-keyframes pulseWarning {
    0% {
        border-color: #F8D486;
    }

    100% {
        border-color: #F8BB86;
    }
}

@keyframes pulseWarning {
    0% {
        border-color: #F8D486;
    }

    100% {
        border-color: #F8BB86;
    }
}

.pulseWarning {
    -webkit-animation: pulseWarning 0.75s infinite alternate;
    animation: pulseWarning 0.75s infinite alternate;
}

@-webkit-keyframes pulseWarningIns {
    0% {
        background-color: #F8D486;
    }

    100% {
        background-color: #F8BB86;
    }
}

@keyframes pulseWarningIns {
    0% {
        background-color: #F8D486;
    }

    100% {
        background-color: #F8BB86;
    }
}

.pulseWarningIns {
    -webkit-animation: pulseWarningIns 0.75s infinite alternate;
    animation: pulseWarningIns 0.75s infinite alternate;
}

@-webkit-keyframes rotate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
    -ms-transform: rotate(45deg) \9;
}

.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
    -ms-transform: rotate(-45deg) \9;
}


.sweet-alert .sa-icon.sa-success {
    border-color: transparent\9;
}

.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
    -ms-transform: rotate(45deg) \9;
}

.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
    -ms-transform: rotate(-45deg) \9;
}


.la-ball-fall,
.la-ball-fall>div {
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.la-ball-fall {
    display: block;
    font-size: 0;
    color: #fff;
}

.la-ball-fall.la-dark {
    color: #333;
}

.la-ball-fall>div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
}

.la-ball-fall {
    width: 54px;
    height: 18px;
}

.la-ball-fall>div {
    width: 10px;
    height: 10px;
    margin: 4px;
    border-radius: 100%;
    opacity: 0;
    -webkit-animation: ball-fall 1s ease-in-out infinite;
    -moz-animation: ball-fall 1s ease-in-out infinite;
    -o-animation: ball-fall 1s ease-in-out infinite;
    animation: ball-fall 1s ease-in-out infinite;
}

.la-ball-fall>div:nth-child(1) {
    -webkit-animation-delay: -200ms;
    -moz-animation-delay: -200ms;
    -o-animation-delay: -200ms;
    animation-delay: -200ms;
}

.la-ball-fall>div:nth-child(2) {
    -webkit-animation-delay: -100ms;
    -moz-animation-delay: -100ms;
    -o-animation-delay: -100ms;
    animation-delay: -100ms;
}

.la-ball-fall>div:nth-child(3) {
    -webkit-animation-delay: 0ms;
    -moz-animation-delay: 0ms;
    -o-animation-delay: 0ms;
    animation-delay: 0ms;
}

.la-ball-fall.la-sm {
    width: 26px;
    height: 8px;
}

.la-ball-fall.la-sm>div {
    width: 4px;
    height: 4px;
    margin: 2px;
}

.la-ball-fall.la-2x {
    width: 108px;
    height: 36px;
}

.la-ball-fall.la-2x>div {
    width: 20px;
    height: 20px;
    margin: 8px;
}

.la-ball-fall.la-3x {
    width: 162px;
    height: 54px;
}

.la-ball-fall.la-3x>div {
    width: 30px;
    height: 30px;
    margin: 12px;
}

/*
 * Animation
 */
@-webkit-keyframes ball-fall {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-145%);
        transform: translateY(-145%);
    }

    10% {
        opacity: .5;
    }

    20% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    80% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    90% {
        opacity: .5;
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(145%);
        transform: translateY(145%);
    }
}

@-moz-keyframes ball-fall {
    0% {
        opacity: 0;
        -moz-transform: translateY(-145%);
        transform: translateY(-145%);
    }

    10% {
        opacity: .5;
    }

    20% {
        opacity: 1;
        -moz-transform: translateY(0);
        transform: translateY(0);
    }

    80% {
        opacity: 1;
        -moz-transform: translateY(0);
        transform: translateY(0);
    }

    90% {
        opacity: .5;
    }

    100% {
        opacity: 0;
        -moz-transform: translateY(145%);
        transform: translateY(145%);
    }
}

@-o-keyframes ball-fall {
    0% {
        opacity: 0;
        -o-transform: translateY(-145%);
        transform: translateY(-145%);
    }

    10% {
        opacity: .5;
    }

    20% {
        opacity: 1;
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    80% {
        opacity: 1;
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    90% {
        opacity: .5;
    }

    100% {
        opacity: 0;
        -o-transform: translateY(145%);
        transform: translateY(145%);
    }
}

@keyframes ball-fall {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-145%);
        -moz-transform: translateY(-145%);
        -o-transform: translateY(-145%);
        transform: translateY(-145%);
    }

    10% {
        opacity: .5;
    }

    20% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    80% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
    }

    90% {
        opacity: .5;
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(145%);
        -moz-transform: translateY(145%);
        -o-transform: translateY(145%);
        transform: translateY(145%);
    }
}